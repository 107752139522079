import { useCallback, useContext } from "react"
import { Context } from "src/app/Context"
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export const useVm = () => {
    const { currentRole } = useContext(Context)
    const navigate = useNavigate()

    const signInWithGoogle = useCallback(async () => {
        const auth = getAuth()
        auth.languageCode = 'it'
        const provider = new GoogleAuthProvider()
        try {
            const result = await signInWithPopup(auth, provider)
            const credential = GoogleAuthProvider.credentialFromResult(result)
            const token = credential?.accessToken
            // const user = result.user
            sessionStorage.setItem("gm-token", token ?? "")
            navigate('/')
        } catch (error: any) {
        }
    }, [])

    return {
        signInWithGoogle
    }
}