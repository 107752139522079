import classNames from 'classnames'
import Styles from './LoadingSpin.module.scss'

export const LoadingSpin = ({ className }: {
    className?: string
}) => {
    return <div className={classNames(Styles.spin, className)}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
    </div>
}
