import React from 'react';
import classNames from 'classnames'
import Styles from './PostItem.module.scss'
import { Post } from 'src/models';
import dayjs from 'dayjs';

export const PostItem: React.FC<{
  className?: string,
  post: Post,
  clicked?: boolean
  onClick: (post?: Post) => any,
}> = ({ className, post, clicked, onClick }) => {

  return <div
    className={classNames(Styles.postItem, className, clicked && Styles.clicked)}
    onClick={() => {
      console.log(post)
      onClick(post)
    }} >
    {post.img &&
      <div className={Styles.image} >
        <img src={post.img} />
      </div>
    }
    <div className={Styles.contentDiv}>
      <div className={Styles.content}>{post.content}</div>
      <div className={Styles.date}>{dayjs(post.date).fromNow()}</div>
    </div>
  </div>
}