import { App, message } from "antd"
import axios from "axios"
import { isEmpty } from "lodash"
import { useState, useEffect, useCallback, useContext } from "react"
import {
	getTeam as getTeamByApi,
	updateTeam as updateTeamByApi
} from "src/api"
import { Context } from "src/app/Context"
import { compressConvertImage } from "src/utils"


export const useVm = () => {
	const { currentRole } = useContext(Context)
	const currentTeam = currentRole?.team
	const [image, setImage] = useState<string | undefined>()
	const [link, setLink] = useState<string | undefined>()
	const [description, setDescription] = useState<string | undefined>()
	const [imageNew, setImageNew] = useState<string | undefined>()
	const [linkNew, setLinkNew] = useState<string | undefined>()
	const [descriptionNew, setDescriptionNew] = useState<string | undefined>()
	const [isUploading, setIsUploading] = useState(false)

	const load = useCallback(async () => {
		if (isEmpty(currentTeam)) return
		try {
			const team = await getTeamByApi(currentTeam!)
			setLink(team?.url)
			setDescription(team?.description)
		} catch (error: any) {
			message.error("載入失敗: " + error.message)
		}
		const imageNameEncoded = encodeURIComponent(`/teams/${currentTeam}/profile.jpg`)
		setImage(currentTeam ? `${process.env.REACT_APP_STORAGE_HOST}${imageNameEncoded}?alt=media` : undefined)
		console.log(`${process.env.REACT_APP_STORAGE_HOST}${imageNameEncoded}?alt=media`)
	}, [currentTeam])

	const readImageNew = async (file?: File) => {
		if (file) setImageNew(await compressConvertImage(file))
	}

	const upload = async () => {
		try {
			if (!currentTeam) return
			message.loading("上傳中")
			setIsUploading(true)
			await updateTeamByApi({
				team: currentTeam,
				description: descriptionNew ?? description ?? '',
				image: imageNew,
				url: linkNew
			})
			message.success("上傳成功")
			load()
		} catch (error: any) {
			message.error("上傳失敗: " + error.message)
		}
		setIsUploading(false)
	}

	useEffect(() => {
		load()
	}, [currentTeam])

	return {
		image,
		link,
		description,
		imageNew,
		linkNew,
		descriptionNew,
		isUploading,
		readImageNew,
		setLinkNew,
		setDescriptionNew,
		upload
	}
}