import React from 'react';
import Styles from './SharesWKPage.module.scss'
import { useVm } from './SharesWKPage.vm';
import { isEmpty } from 'lodash';
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'
import { NewsItem } from 'src/components/NewsItem';


export const SharesWKPage: React.FC = () => {
  const {
    currentShare,
    setCurrentShare,
    shares,
    syncShare,
    isProcessing
  } = useVm()

  return <div className={Styles.page}>
    <div className={Styles.header}>
      <h3>{'已同步分享'}</h3>
      <Button
        className={Styles.btnSync}
        type="primary"
        loading={isProcessing}
        onClick={syncShare}>
        {'手動同步'}
      </Button>
    </div>
    {!shares && <LoadingOutlined className={Styles.loading} spin />}
    {shares && isEmpty(shares) && <label className={Styles.empty}>{'尚未同步任何分享'}</label>}
    <div>

    </div>
    <div className={Styles.shares}>
      {shares && shares.map((share, i) => (
        <NewsItem
          key={i}
          className={Styles.newsItem}
          news={share}
          clicked={currentShare?.title === share.title}
          onClick={() => { setCurrentShare(share) }}
        />
      ))}
    </div>
  </div>
}