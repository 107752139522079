import { createContext, useEffect, useState } from "react";
import { getAuth, signOut } from "firebase/auth";
import { Role } from "src/models";
import { getMyRoles as getMyRolesByApi } from "src/api";
import { Routes, getRoutes } from "./routes";
import { attempt } from "src/utils";
import { message } from "antd";

type ContextProps = {
  setAuth: (roles: Role[], currentRole?: Role) => any
  setCurrentRole: (currentRole?: Role) => any
  logout: () => any
} & Auth

export const Context = createContext<ContextProps>({
  roles: [],
  routesPermitted: [],
  setAuth: () => { },
  setCurrentRole: () => { },
  logout: () => { }
})

type Auth = {
  uid?: string
  roles: Role[],
  currentRole?: Role,
  routesPermitted: Routes[]
  authState?: 'success' | 'loading' | 'error'
}
const auth: Auth = {
  roles: [],
  currentRole: undefined,
  routesPermitted: []
}

const getRoleFromStorage = () => {
  // return undefined if no role is found or catch error
  try {
    const role = JSON.parse(sessionStorage.getItem('role')!)
    return role
  } catch (error) {
    return undefined
  }
}
const setRoleToStorage = (role?: Role) => {
  if (role) {
    sessionStorage.setItem('role', JSON.stringify(role))
  } else {
    sessionStorage.removeItem('role')
  }
}

export const useAppContext = () => {
  const [{ roles, currentRole, routesPermitted, authState }, _setAuth] = useState<Auth>(auth)

  const setAuth = (roles: Role[], currentRole?: Role) => {
    const role = currentRole ?? getRoleFromStorage() ?? roles[0]
    _setAuth(v => ({
      ...v,
      roles,
      currentRole: role,
      routesPermitted: getRoutes(role),
      authState: 'success'
    }))
  }

  const setCurrentRole = (currentRole?: Role) => {
    const role = roles.find(r => r.team === currentRole?.team)
    setRoleToStorage(role)
    _setAuth(v => ({
      ...v,
      roles,
      currentRole: role,
      routesPermitted: role ? getRoutes(role) : []
    }))
  }

  const logout = async () => {
    await signOut(getAuth())
    _setAuth({ roles: [], routesPermitted: [] })
  }

  const loadMyRoles = async (nRetry: number) => {
    if (nRetry === 2) {
      message.info('首次登入時，系統可能需要10-20秒登錄')
    }

    const myRoles = await getMyRolesByApi()
    setAuth(myRoles)
  }

  useEffect(() => {
    _setAuth(v => ({ ...v, authState: 'loading' }))
    getAuth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          await attempt(loadMyRoles, 3, 4000)
          _setAuth(v => ({ ...v, authState: 'success' }))
        } catch (error: any) {
          console.error(error.message)
          await logout()
          _setAuth(v => ({ ...v, authState: 'error' }))
        }
      } else {
        // No user is signed in.
        console.log('No user is signed in')
        _setAuth(v => ({ ...v, authState: undefined }))
      }
    })
  }, [])

  const props: ContextProps = {
    roles,
    currentRole,
    routesPermitted,
    authState,
    setAuth,
    setCurrentRole,
    logout
  }
  return props
}