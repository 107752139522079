import { message } from "antd"
import { useState, useEffect, useContext } from "react"
import { Event } from "src/models"
import { isEmpty, last } from "lodash"
import {
  createEvent as createEventByApi,
  getEvents as getEventsByApi,
  updateEvent as updateEventByApi,
  deleteEvent as deleteEventByApi
} from "src/api"
import { Context } from "src/app/Context"
import { compressConvertImage, convertTimestampToDate } from "src/utils"


export const useVm = () => {
  const { currentRole } = useContext(Context)
  const currentTeam = currentRole?.team
  const [eventEditing, setEventEditing] = useState<Partial<Event> | undefined>()
  const [isUploading, setIsUploading] = useState(false)
  const [events, setEvents] = useState<Event[]>([])

  const updateEventEditing = (key: keyof Event, value: any) => {
    setEventEditing(v => ({
      ...(v ?? {}),
      [key]: value
    }))
  }

  const readImageNew = async (file?: File) => {
    if (file) updateEventEditing('img', await compressConvertImage(file))
  }

  const isEditingOK = (
    !isEmpty(eventEditing?.title)
    && !isEmpty(eventEditing?.description)
    && eventEditing?.startAt
    && eventEditing?.endAt
  )

  const loadEvents = async () => {
    if (!currentTeam) return

    try {
      const result = await getEventsByApi(currentTeam, last(events))
      setEvents(convertTimestampToDate(result))
    } catch (error: any) {
      message.error('載入失敗: ' + error.message)
    }
  }

  const uploadEvent = async () => {
    if (!eventEditing || !currentTeam) return
    const event = { ...eventEditing, team: currentTeam }
    // when img is web url, it means the image is not changed
    if (event.img?.startsWith('http')) delete event.img
    try {
      setIsUploading(true)
      if (isEmpty(event?.id)) {
        await createEventByApi(event)
      } else {
        await updateEventByApi(event)
      }
      setEventEditing(undefined)
      loadEvents()
      message.success('成功')
    } catch (error: any) {
      message.error('失敗: ' + error.message)
    }
    setIsUploading(false)
  }

  const deleteEvent = async ({ id, team }: { id: string, team: string }) => {
    try {
      message.loading('處理中')
      await deleteEventByApi({ id, team })
      setEventEditing(undefined)
      loadEvents()
      message.destroy()
      message.success('成功')
    } catch (error: any) {
      message.error('失敗: ' + error.message)
    }
  }

  useEffect(() => {
    loadEvents()
  }, [currentTeam])

  return {
    events,
    eventEditing,
    setEventEditing,
    updateEventEditing,
    uploadEvent,
    deleteEvent,
    readImageNew,
    isEditingOK,
    isUploading
  }
}