import { message } from "antd"
import { useState, useCallback, useEffect } from "react"
import { Share } from "src/models"
import {
    getShares as getSharesByApi,
    syncShareWK as syncShareWKByApi
} from "src/api"


export const useVm = () => {
    const [link, setLink] = useState<string>('')
    const [currentShare, setCurrentShare] = useState<Share | undefined>()
    const [shares, setShares] = useState<Share[] | undefined>()
    const [isProcessing, setIsProcessing] = useState(false)

    const syncShare = useCallback(async () => {
        try {
            setIsProcessing(true)
            await syncShareWKByApi()
            setLink('')
            message.success('成功')
        } catch (error: any) {
            message.error('失敗: ' + error.message)
        }
        setIsProcessing(false)
    }, [link])

    const load = async () => {
        try {
            const result = await getSharesByApi('share-wk')
            setShares(result)
        } catch (error: any) {
            message.error('載入失敗: ' + error.message)
        }
    }

    useEffect(() => {
        load()
    }, [])

    return {
        currentShare,
        setCurrentShare,
        shares,
        syncShare,
        isProcessing
    }
}