import { message } from "antd"
import { useState, useEffect, useContext } from "react"
import { Post } from "src/models"
import { isEmpty, last } from "lodash"
import {
  getPosts as getPostsByApi,
  createPost as createPostByApi,
  updatePost as updatePostByApi,
  deletePost as deletePostByApi
} from "src/api"
import { Context } from "src/app/Context"
import { compressConvertImage, convertTimestampToDate } from "src/utils"


export const useVm = () => {
  const { currentRole } = useContext(Context)
  const currentTeam = currentRole?.team
  const [postEditing, setPostEditing] = useState<Partial<Post> | undefined>()
  const [isUploading, setIsUploading] = useState(false)
  const [posts, setPosts] = useState<Post[]>([])

  const updatePostEditing = (key: keyof Post, value: any) => {
    setPostEditing(v => ({
      ...(v ?? {}),
      [key]: value
    }))
  }

  const readImageNew = async (file?: File) => {
    if (file) updatePostEditing('img', await compressConvertImage(file))
  }

  const isEditingOK = (
    !isEmpty(postEditing?.content)
    && !isEmpty(postEditing?.img)
    && postEditing?.date
  )

  const loadPosts = async () => {
    if (!currentTeam) return

    try {
      const result = await getPostsByApi(currentTeam, last(posts))
      setPosts(convertTimestampToDate(result))
    } catch (error: any) {
      console.log(error)
      message.error('載入失敗: ' + error.message)
    }
  }

  const uploadPost = async () => {
    if (!postEditing || !currentTeam) return
    const post = { ...postEditing, team: currentTeam }
    try {
      setIsUploading(true)
      if (isEmpty(post?.id)) {
        await createPostByApi(post)
      } else {
        await updatePostByApi(post)
      }
      setPostEditing(undefined)
      loadPosts()
      message.success('成功')
    } catch (error: any) {
      message.error('失敗: ' + error.message)
    }
    setIsUploading(false)
  }

  const deletePost = async ({ id, team }: { id: string, team: string }) => {
    try {
      setIsUploading(true)
      message.loading('處理中')
      await deletePostByApi({ id, team })
      setPostEditing(undefined)
      loadPosts()
      message.destroy()
      message.success('成功')
    } catch (error: any) {
      message.error('失敗: ' + error.message)
    }
    setIsUploading(false)
  }

  useEffect(() => {
    loadPosts()
  }, [currentTeam])

  return {
    posts,
    postEditing,
    setPostEditing,
    updatePostEditing,
    uploadPost,
    deletePost,
    readImageNew,
    isEditingOK,
    isUploading,
    currentRole
  }
}