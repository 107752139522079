export const routes = {
    events: 'events',
    members: 'members',
    settings: 'settings',
    reports: 'reports',
    sharesWK: 'shares-wk',
    sharesMC: 'shares-mc',
    news: 'news',
    signIn: 'sign-in',
    posts: 'posts'
}
export type Routes = keyof typeof routes

export const getRoutes = ({ role, team }: { role: string, team: string }): Routes[] => {
    const arr: Routes[] = []
    if (team === '管理員') {
        arr.push('sharesWK', 'reports', 'news')
    } else if (team === '分享') {
        arr.push('sharesMC')
    } else {
        arr.push('events', 'posts', 'settings')
    }
    return arr
}