import { message } from "antd"
import { useState, useCallback, useEffect } from "react"
import { News, Share } from "src/models"
import {
    getNews,
    getShares as getSharesByApi,
    syncNews as syncNewsByApi,
} from "src/api"


export const useVm = () => {
    const [newsList, setNewsList] = useState<News[] | undefined>()
    const [currentNews, setCurrentNews] = useState<News | undefined>()
    const [isProcessing, setIsProcessing] = useState(false)

    const syncNews = useCallback(async () => {
        try {
            setIsProcessing(true)
            await syncNewsByApi()
            message.success('成功')
            await load()
        } catch (error: any) {
            message.error('失敗: ' + error.message)
        }
        setIsProcessing(false)
    }, [])

    const load = async () => {
        try {
            const result = await getNews()
            setNewsList(result)
        } catch (error: any) {
            message.error('載入失敗: ' + error.message)
        }
    }

    useEffect(() => {
        load()
    }, [])

    return {
        currentNews,
        setCurrentNews,
        newsList,
        syncNews,
        isProcessing
    }
}