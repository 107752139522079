import React from 'react';
import classNames from 'classnames'
import Styles from './ReportModal.module.scss'
import { Report } from 'src/models';
import { Modal } from 'antd';

export const ReportModal: React.FC<{
  className?: string,
  report?: Report,
  open?: boolean,
  onCancel: () => any,
}> = ({ className, report, open, onCancel }) => {

  return <Modal
    className={classNames(Styles.report, className)}
    open={open ?? false}
    cancelText={'關閉'}
    okButtonProps={{ style: { display: 'none' } }}
    onCancel={onCancel}>
    <div className={Styles.modal}>
      <div>
        <h1>每週報告</h1>
        <label>-</label>
        <h2>{report?.date}</h2>
      </div>
      <h3>報告事項</h3>
      <ol>
        {report?.issues.map((issue, i) => <li key={i}>{issue}</li>)}
      </ol>
      <h3>週五祈禱會</h3>
      <table>
        <tbody>
          {report?.weeklyPrayer.map((row, i) => (
            <tr key={i}>
              {row.map((col, j) => <td key={j}>{col}</td>)}
            </tr>
          ))}
        </tbody>
      </table>
      <h3>週二晨更</h3>
      <table>
        <tbody>
          {report?.morningPrayer.map((row, i) => (
            <tr key={i}>
              {row.map((col, j) => <td key={j}>{col}</td>)}
            </tr>
          ))}
        </tbody>
      </table>
      <h3>行事曆</h3>
      <table>
        <tbody>
          {report?.calendar.map((row, i) => (
            <tr key={i}>
              {row.map((col, j) => <td key={j}>{col}</td>)}
            </tr>
          ))}
        </tbody>
      </table>
      <h3>代禱事項</h3>
      <ol>
        {report?.prayer.map((issue, index) => <li key={index}>{issue}</li>)}
      </ol>
    </div>
  </Modal>
}