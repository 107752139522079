import React from 'react'
import Styles from './SettingsPage.module.scss'
import { useVm } from './SettingsPage.vm'
import { Button, Input } from 'antd'
import { isEmpty } from 'lodash'
import classNames from 'classnames'
import { ImageUploader } from 'src/components/ImageUploader'

export const SettingsPage: React.FC = () => {
  const {
    image,
    link,
    description,
    imageNew,
    linkNew,
    descriptionNew,
    isUploading,
    readImageNew,
    setLinkNew,
    setDescriptionNew,
    upload
  } = useVm()

  return <div className={Styles.page}>
    <div className={Styles.uploadDiv}>
      <div className={Styles.title}>{'封面照'}</div>
      <ImageUploader
        className={Styles.upload}
        img={imageNew ?? image}
        onReadImage={readImageNew} />
    </div>
    <div className={Styles.inputs}>
      <div className={Styles.title}>{'外部連結 (IG, Facebook)'}</div>
      <Input
        className={Styles.input}
        placeholder='https://your-team-link'
        value={linkNew ?? link}
        onChange={e => setLinkNew(e.currentTarget.value)} />
      <div className={Styles.title}>{'簡介'}</div>
      <Input.TextArea
        className={classNames(Styles.input, Styles.textarea)}
        placeholder='...'
        value={descriptionNew ?? description}
        onChange={e => setDescriptionNew(e.currentTarget.value)} />
      <Button
        type='primary'
        disabled={isEmpty(imageNew) && isEmpty(linkNew) && isEmpty(descriptionNew)}
        onClick={upload}
        loading={isUploading}>
        {'更新'}
      </Button>
    </div>
  </div >
}