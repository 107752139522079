import { message } from "antd"
import { useState, useCallback, useEffect } from "react"
import { Share } from "src/models"
import {
    getShares as getSharesByApi,
    upsertShareCM as upsertShareCMByApi
} from "src/api"


export const useVm = () => {
    const [link, setLink] = useState<string>('')
    const [shares, setShares] = useState<Share[] | undefined>()
    const [isUploading, setIsUploading] = useState(false)

    const syncShare = useCallback(async () => {
        try {
            setIsUploading(true)
            await upsertShareCMByApi(link)
            setLink('')
            message.success('成功')
        } catch (error: any) {
            message.error('失敗: ' + error.message)
        }
        setIsUploading(false)
    }, [link])

    const load = async () => {
        try {
            const result = await getSharesByApi('share-mc')
            setShares(result)
        } catch (error: any) {
            message.error('載入失敗: ' + error.message)
        }
    }

    useEffect(() => {
        load()
    }, [])

    return {
        shares,
        link,
        setLink,
        syncShare,
        isUploading
    }
}