import React from 'react'
import Styles from './PostsPage.module.scss'
import { useVm } from './PostsPage.vm'
import { isNil } from 'lodash'
import { Button, DatePicker, Input, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import classNames from 'classnames'
import { PostItem } from 'src/components/PostItem'
import { ImageUploader } from 'src/components/ImageUploader'

export const PostsPage: React.FC = () => {
  const {
    posts,
    postEditing,
    setPostEditing,
    updatePostEditing,
    uploadPost,
    deletePost,
    readImageNew,
    isEditingOK,
    isUploading,
    currentRole
  } = useVm()
  const [modal, contextHolder] = Modal.useModal()

  return <div className={Styles.page}>
    {currentRole &&
      <div className={Styles.preview}>
        {'帖子網址: '}
        <a
          target='_blank'
          rel='noreferrer'
          href={`https://tpwkphc.org/team?team=${currentRole?.team}`}>
          {`https://tpwkphc.org/team?team=${currentRole?.team}`}
        </a>
      </div>
    }
    <Button
      className={Styles.addButton}
      onClick={() => setPostEditing({})}
      icon={<PlusOutlined />}>
      {'新增帖子'}
    </Button>
    <div className={Styles.items}>
      {posts.map(post => (
        <PostItem
          key={post.id}
          post={post}
          onClick={setPostEditing} />
      ))}
    </div>
    <Modal
      open={!isNil(postEditing)}
      onCancel={() => setPostEditing(undefined)}
      onOk={uploadPost}
      footer={[
        (postEditing?.id &&
          <Button
            key='delete'
            type='primary'
            danger
            loading={isUploading}
            onClick={async () => {
              const confirmed = await modal.confirm({
                title: '確認',
                content: '確定移除這個帖子嗎？',
                okText: '確定',
                cancelText: '取消'
              })
              if (!confirmed) return
              deletePost({ id: postEditing.id!, team: postEditing.team! })
            }}>
            {'移除'}
          </Button>
        ),
        <Button
          key='ok'
          type='primary'
          onClick={uploadPost}
          loading={isUploading}
          disabled={!isEditingOK}>
          {postEditing?.id ? '更新' : '建立'}
        </Button>
      ]} >
      <div className={Styles.editor}>
        <div className={Styles.hint}>{'* 為必需填寫'}</div>
        <div className={Styles.title}>{'照片'}</div>
        <ImageUploader
          className={Styles.image}
          img={postEditing?.img}
          onReadImage={readImageNew} />
        <div className={Styles.content}>{'內容 *'}</div>
        <Input.TextArea
          className={classNames(Styles.input, Styles.textarea)}
          placeholder='...'
          value={postEditing?.content}
          onChange={e => updatePostEditing('content', e.currentTarget.value)} />
        <div className={Styles.title}>{'日期 *'}</div>
        <DatePicker
          size='middle'
          placeholder={'日期'}
          value={postEditing?.date ? dayjs(postEditing?.date) : null}
          onChange={d => {
            const date = d?.toDate()
            if (date) updatePostEditing('date', date)
          }} />
      </div>
      {contextHolder}
    </Modal>
  </div >
}