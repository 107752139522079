import React, { useEffect } from 'react';
import Styles from './SignInPage.module.scss'
import { useVm } from './SignInPage.vm';
import { ReactComponent as GoogleIcon } from 'src/assets/google.svg'
import { useAppContext } from 'src/app/Context';
import { LoadingSpin } from 'src/components/LoadingSpin';

export const SignInPage: React.FC = () => {
  const { authState } = useAppContext()
  const {
    signInWithGoogle
  } = useVm()

  useEffect(() => {
  }, [])

  return <div className={Styles.page}>
    <h3>{'五旬節聖潔會大埔永光堂'}</h3>
    <h1>{'網頁管理系統'}</h1>
    {
      (authState === 'loading' &&
        <div className={Styles.loading}>
          <LoadingSpin />
          <div>{'載入中...'}</div>
        </div>
      ) ||
      <>
        {authState === 'error' &&
          <div className={Styles.errorHint}>
            {'發生錯誤, 請重新嘗試'}
          </div>
        }

        <button className={Styles.btnSignIn} onClick={signInWithGoogle}>
          <GoogleIcon />
          <label>{'使用Google帳戶登入'}</label>
        </button>
      </>
    }
  </div >
}