import React from 'react';
import Styles from './ReportsPage.module.scss'
import { useVm } from './ReportsPage.vm';
import { isEmpty, isNil } from 'lodash';
import { Button, DatePicker, Input, Modal, Upload } from 'antd';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';
import { ReportItem } from './ReportItem';
import { ReportModal } from './ReportModal';

export const ReportsPage: React.FC = () => {
  const {
    currentReport,
    setCurrentReport,
    reports,
    upload,
    isUploading
  } = useVm()

  return <div className={Styles.page}>
    <div className={Styles.uploadDiv}>
      <Upload.Dragger
        className={Styles.upload}
        showUploadList={false}
        customRequest={() => { }}
        fileList={[]}
        onChange={e => { upload(e.fileList[0].originFileObj) }}>
        {!isUploading &&
          <>
            <div className={Styles.title}>{'上載報告'}</div>
            <UploadOutlined className={Styles.icon} />
          </>
        }
        {isUploading && <LoadingOutlined className={Styles.loading} spin />}
      </Upload.Dragger>
    </div>
    <div className={Styles.reports}>
      <h3>{'已上載報告'}</h3>
      {!reports && <LoadingOutlined className={Styles.loading} spin />}
      {reports && isEmpty(reports) && <label className={Styles.empty}>{'尚未同步任何分享'}</label>}
      {reports?.map(report => (
        <ReportItem
          key={report.date}
          className={Styles.report}
          report={report}
          onClick={setCurrentReport} />
      ))}
    </div>
    <ReportModal
      className={Styles.modal}
      report={currentReport}
      open={!isNil(currentReport)}
      onCancel={() => setCurrentReport(undefined)} />
  </div>
}