import React from 'react';
import Styles from './SharesMCPage.module.scss'
import { useVm } from './SharesMCPage.vm';
import { isEmpty } from 'lodash';
import { Button, Input, Space } from 'antd';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons'

export const SharesMCPage: React.FC = () => {
  const {
    shares,
    link,
    setLink,
    syncShare,
    isUploading
  } = useVm()

  return <div className={Styles.page}>
    <h3 >{'連結分享'}</h3>
    <Space.Compact className={Styles.addLink}>
      <Input
        placeholder={'https://your-share-site/some-post'}
        value={link}
        onChange={e => setLink(e.currentTarget.value)} />
      <Button
        type="primary"
        icon={<UploadOutlined />}
        disabled={isEmpty(link)}
        loading={isUploading}
        onClick={syncShare} />
    </Space.Compact>
    <h3>{'已連結的分享'}</h3>
    {!shares && <LoadingOutlined className={Styles.loading} spin />}
    {shares && isEmpty(shares) && <label className={Styles.empty}>{'尚未連結任何分享'}</label>}
  </div >
}