import { ConfigProvider, theme } from "antd";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { EventsPage } from "src/pages/events/EventsPage";
import { SettingsPage } from "src/pages/settings/SettingsPage";
import Styles from "./App.module.scss";
import { Context, useAppContext } from "./Context";
import { routes } from "./routes";
import { RouteRoot } from "./RouteRoot";
import { ReportsPage } from "src/pages/reports/ReportsPage";
import { SharesWKPage } from "src/pages/shares-wk/SharesWKPage";
import { SharesMCPage } from "src/pages/shares-mc/SharesMCPage";
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { NewsPage } from "src/pages/news/NewsPage";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { PostsPage } from "src/pages/posts/PostsPage";
import "dayjs/locale/zh-hk";

dayjs.extend(relativeTime)
dayjs.locale('zh-hk')

const firebaseConfig = {
  apiKey: "AIzaSyANvF7iHFeSxGlnXKUB8tRYfvGOZU_jwLU",
  authDomain: "mp.tpwkphc.org",
  projectId: "tpwkphc-46c04",
  storageBucket: "tpwkphc-46c04.appspot.com",
  messagingSenderId: "340650167942",
  appId: "1:340650167942:web:c0d50632789f00643d4b07",
  measurementId: "G-N75H92WXS5"
}
const app = initializeApp(firebaseConfig)
getAnalytics(app)

const App = () => {
  const context = useAppContext()

  const router = createBrowserRouter([
    {
      path: '/',
      element: <RouteRoot />,
      children: [
        { path: `/${routes.reports}`, element: <ReportsPage /> },
        { path: `/${routes.sharesWK}`, element: <SharesWKPage /> },
        { path: `/${routes.sharesMC}`, element: <SharesMCPage /> },
        { path: `/${routes.events}`, element: <EventsPage /> },
        { path: `/${routes.settings}`, element: <SettingsPage /> },
        { path: `/${routes.news}`, element: <NewsPage /> },
        { path: `/${routes.posts}`, element: <PostsPage /> },
      ]
    },
  ])

  return (
    <Context.Provider value={context}>
      <ConfigProvider theme={{
        algorithm: theme.darkAlgorithm,
        token: { colorPrimary: '#287aff' },
        components: {
          Button: { algorithm: true }
        }
      }}>
        <div className={Styles.App}>
          <RouterProvider router={router} />
        </div>
      </ConfigProvider>
    </Context.Provider>
  );
}

export default App;
