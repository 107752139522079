import { message } from "antd"
import { useState, useEffect } from "react"
import { Report } from "src/models"
import {
    upsertReport as upsertReportByApi,
    getReports as getReportsByApi,
} from "src/api"
import { toDataUrl } from "src/utils"


export const useVm = () => {
    const [currentReport, setCurrentReport] = useState<Report | undefined>()
    const [reports, setReports] = useState<Report[] | undefined>()
    const [isUploading, setIsUploading] = useState(false)

    const upload = async (file?: File) => {
        if (!file) return
        try {
            setIsUploading(true)
            const dataUrl = await toDataUrl(file)
            await upsertReportByApi(dataUrl)
            setIsUploading(false)
            message.success('成功')
            await load()
        } catch (error: any) {
            message.error('失敗: ' + error.message)
            setIsUploading(false)
        }
    }

    const load = async () => {
        try {
            const result = await getReportsByApi()
            setReports(result)
        } catch (error: any) {
            message.error('載入失敗: ' + error.message)
        }
    }

    useEffect(() => {
        load()
    }, [])

    return {
        currentReport,
        setCurrentReport,
        reports,
        upload,
        isUploading
    }
}