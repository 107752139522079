import React from 'react';
import classNames from 'classnames'
import Styles from './NewsItem.module.scss'
import { News, Share } from 'src/models';

export const NewsItem: React.FC<{
  className?: string,
  key?: string | number,
  news: News,
  clicked?: boolean
  onClick: (news?: News) => any,
}> = ({ className, key, news, clicked, onClick }) => {

  return <div
    key={key}
    className={classNames(Styles.newsItem, className, clicked && Styles.clicked)}
    onClick={() => onClick(news)} >
    {news.img &&
      <div className={Styles.image} >
        <img src={news.img} />
      </div>
    }
    <div className={Styles.content}>
      {<div className={Styles.type}>
        {news.type === 'share-wk' && '牧者分享'}
        {news.type === 'event' && '活動'}
      </div>
      }
      <h3>
        {news.title}
      </h3>
      <p>
        {news.description?.split('\n').map(v => (
          <>
            {v}<br />
          </>
        ))}
      </p>
      <a href={news.url} target="_blank">{'更多...'}</a>
    </div>
  </div>
}