import React from 'react';
import Styles from './NewsPage.module.scss'
import { useVm } from './NewsPage.vm';
import { isEmpty } from 'lodash';
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'
import { NewsItem } from 'src/components/NewsItem';


export const NewsPage: React.FC = () => {
  const {
    currentNews,
    setCurrentNews,
    newsList,
    syncNews,
    isProcessing
  } = useVm()

  return <div className={Styles.page}>
    <div className={Styles.header}>
      <Button
        className={Styles.btnSync}
        type="primary"
        loading={isProcessing}
        onClick={syncNews}>
        {'手動同步'}
      </Button>
    </div>
    {!newsList && <LoadingOutlined className={Styles.loading} spin />}
    {newsList && isEmpty(newsList) && <label className={Styles.empty}>{'尚未有任何最新消息'}</label>}
    <div>

    </div>
    <div className={Styles.shares}>
      {newsList && newsList.map((news, i) => (
        <NewsItem
          key={i}
          className={Styles.newsItem}
          news={news}
          clicked={currentNews?.title === news.title}
          onClick={() => { setCurrentNews(news) }}
        />
      ))}
    </div>
  </div>
}