import React from 'react';
import classNames from 'classnames'
import Styles from './ReportItem.module.scss'
import { Report } from 'src/models';

export const ReportItem: React.FC<{
  className?: string,
  report: Report,
  onClick: (report: Report) => any,
}> = ({ className, report, onClick }) => {

  return <div
    className={classNames(Styles.report, className)}
    onClick={() => onClick(report)}>
    <div className={Styles.date}>{report.date}</div>
  </div>
}