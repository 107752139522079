import type { MenuProps } from 'antd'
import { Button, Menu, Select } from 'antd'
import {
  BarsOutlined,
  CalendarOutlined,
  FileTextOutlined,
  MenuOutlined,
  SettingOutlined,
  HeartOutlined,
  MediumOutlined
} from '@ant-design/icons'
import classNames from 'classnames'
import { useWindowSize } from 'src/hooks/useWindowSize';
import { useContext, useMemo, useState } from 'react';
import Styles from './ActionBar.module.scss'
import { Context } from './Context';
import { useLocation, useNavigate } from 'react-router-dom';
import { Routes, routes } from './routes';
import { compact } from 'lodash';

type MenuItem = Required<MenuProps>['items'][number];
const Tab: Partial<Record<Routes, { label: string, route: string, icon: React.FC }>> = {
  sharesWK: { label: '牧者分享', route: routes.sharesWK, icon: HeartOutlined },
  reports: { label: '崇拜報告', route: routes.reports, icon: FileTextOutlined },
  sharesMC: { label: '分享', route: routes.sharesMC, icon: MediumOutlined },
  events: { label: '活動', route: routes.events, icon: CalendarOutlined },
  settings: { label: '設定', route: routes.settings, icon: SettingOutlined },
  news: { label: '最新消息', route: routes.news, icon: BarsOutlined },
  posts: { label: '帖子', route: routes.posts, icon: FileTextOutlined },
}

export const ActionBar = () => {
  const { roles, currentRole, routesPermitted, setCurrentRole, logout } = useContext(Context)
  const navigate = useNavigate()
  const location = useLocation()
  const { width } = useWindowSize()
  const [isMenuShown, setIsMenuShown] = useState(false)
  const isMoble = width < 768

  const items: MenuItem[] = useMemo(() => {
    const { role, team } = currentRole ?? {}
    if (!team || !role) return []

    // assign tabs based on role
    const tabs = compact(routesPermitted.map(v => Tab[v]))
    return tabs.map(({ label, icon: Icon, route }) => ({
      label,
      icon: <Icon />,
      key: `/${route}`,
      onClick: () => {
        navigate(`/${route}`)
        setIsMenuShown(false)
      }
    }))
  }, [currentRole])

  return <div className={classNames(Styles.actionBar)}>
    <div className={classNames(Styles.topBar)}>
      <Button
        className={Styles.btnMenu}
        type="text"
        icon={<MenuOutlined />}
        onClick={() => setIsMenuShown(v => !v)} />
      <h1>{'網頁管理系統'}</h1>
      <div className={Styles.spacer} />
      <Select
        className={Styles.teams}
        value={currentRole?.team}
        placeholder={'選擇團隊'}
        bordered={false}
        options={[
          ...roles.map(v => ({ label: v.team, value: v?.team })),
          { label: '登出', value: null }
        ]}
        onChange={async (v) => {
          if (v) setCurrentRole(roles.find(r => r.team === v))
          else {
            await logout()
            navigate('/')
          }
        }}
      />{(() => {
        console.log({ currentRole, roles })
        return <></>
      })()}
    </div>
    {isMenuShown && isMoble &&
      <div className={Styles.menuBackdrop}
        onClick={() => setIsMenuShown(false)} />
    }
    {(isMenuShown || !isMoble) &&
      <Menu
        className={Styles.menu}
        defaultSelectedKeys={['1']}
        mode={isMoble ? 'inline' : 'horizontal'}
        items={items}
        selectedKeys={[location.pathname]}
        forceSubMenuRender={true}
      />
    }
  </div>
}