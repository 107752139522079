import React from 'react'
import Styles from './EventsPage.module.scss'
import { useVm } from './EventsPage.vm'
import { isNil } from 'lodash'
import { Button, DatePicker, Input, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import classNames from 'classnames'
import { NewsItem } from 'src/components/NewsItem'
import { ImageUploader } from 'src/components/ImageUploader'

export const EventsPage: React.FC = () => {
  const {
    events,
    eventEditing,
    setEventEditing,
    updateEventEditing,
    uploadEvent,
    deleteEvent,
    readImageNew,
    isEditingOK,
    isUploading
  } = useVm()
  const [modal, contextHolder] = Modal.useModal()

  return <div className={Styles.page}>
    <Button
      className={Styles.addButton}
      onClick={() => setEventEditing({})}
      icon={<PlusOutlined />}>
      {'新增活動'}
    </Button>
    <div className={Styles.events}>
      {events.map(event => (
        <NewsItem
          news={{ ...event, type: 'event' }}
          onClick={setEventEditing} />
      ))}
    </div>
    <Modal
      open={!isNil(eventEditing)}
      onCancel={() => setEventEditing(undefined)}
      onOk={uploadEvent}
      footer={[
        (eventEditing?.id &&
          <Button
            key='delete'
            type='primary'
            danger
            onClick={async () => {
              const confirmed = await modal.confirm({
                title: '確認',
                content: '確定移除這個帖子嗎？',
                okText: '確定',
                cancelText: '取消'
              })
              if (!confirmed) return
              deleteEvent({ id: eventEditing.id!, team: eventEditing.team! })
            }}>
            {'移除'}
          </Button>
        ),
        <Button
          key='ok'
          type='primary'
          onClick={uploadEvent}
          loading={isUploading}
          disabled={!isEditingOK}>
          {eventEditing?.id ? '更新' : '建立'}
        </Button>
      ]} >
      <div className={Styles.editor}>
        <div className={Styles.hint}>{'* 為必需填寫'}</div>
        <div className={Styles.title}>{'主題 *'}</div>
        <Input
          className={Styles.input}
          value={eventEditing?.title}
          onChange={e => updateEventEditing('title', e.currentTarget.value)} />
        <div className={Styles.title}>{'簡介 *'}</div>
        <Input.TextArea
          className={classNames(Styles.input, Styles.textarea)}
          placeholder='...'
          value={eventEditing?.description}
          onChange={e => updateEventEditing('description', e.currentTarget.value)} />
        <div className={Styles.title}>{'活動日期 *'}</div>
        <DatePicker.RangePicker
          size='middle'
          placeholder={['開始日期', '結束日期']}
          value={(eventEditing?.startAt && eventEditing?.endAt) ? [dayjs(eventEditing?.startAt), dayjs(eventEditing?.endAt)] : null}
          onChange={d => {
            const d0 = d?.[0]?.toDate()
            const d1 = d?.[1]?.toDate()
            if (d0 && d1) {
              updateEventEditing('startAt', d?.[0]?.toDate())
              updateEventEditing('endAt', d?.[1]?.toDate())
            }
          }} />
        <div className={Styles.title}>{'封面照'}</div>
        <ImageUploader
          className={Styles.input}
          img={eventEditing?.img}
          onReadImage={readImageNew} />
        <div className={Styles.title}>{'外部連結 (IG, Facebook)'}</div>
        <Input
          className={Styles.input}
          placeholder='https://your-event-link'
          value={eventEditing?.url}
          onChange={e => updateEventEditing('url', e.currentTarget.value)} />
      </div>
      {contextHolder}
    </Modal>
  </div >
}