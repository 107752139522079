import React from 'react'
import classNames from 'classnames'
import Styles from './ImageUploader.module.scss'
import { Upload } from 'antd'
import { FileImageOutlined } from '@ant-design/icons'
import { RcFile } from 'antd/es/upload'

export const ImageUploader: React.FC<{
  className?: string
  img?: string
  onReadImage: (file?: RcFile) => any
}> = ({ className, img, onReadImage }) => {

  return <Upload.Dragger
    className={classNames(Styles.image, className)}
    showUploadList={false}
    customRequest={() => { }}
    fileList={[]}
    onChange={e => onReadImage(e.fileList[0].originFileObj)}>
    {img && <img src={img} />}
    {!img && <FileImageOutlined className={Styles.icon} />}
  </Upload.Dragger>
}