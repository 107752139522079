import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ActionBar } from './ActionBar';
import { Context } from './Context';
import { routes } from './routes';
import { isEmpty } from 'lodash';
import { SignInPage } from 'src/pages/signIn/SignInPage';


export const RouteRoot: React.FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { roles, routesPermitted, authState } = useContext(Context)

  useEffect(() => {
    if (isEmpty(roles))
      return

    if (!routesPermitted.includes(pathname.split(/\/|\?/)[1] as any)) {
      navigate(routes[routesPermitted[0]])
    }
  }, [pathname, routesPermitted])

  return <div>
    {authState !== 'success' && <SignInPage />}
    {authState === 'success' &&
      <>
        <ActionBar />
        <Outlet />
      </>
    }
  </div >
}